import querystring from "querystring";

const NOW_PLAYING_ENDPOINT = `https://api.spotify.com/v1/me/player/currently-playing`;
const TOKEN_ENDPOINT = `https://accounts.spotify.com/api/token`;
const LYRICS_ENDPOINT = `https://spotify-lyric-api.herokuapp.com`

const client_id = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const client_secret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const refresh_token = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN;

var errCount = 0;
var runs = 0;
var prevLine = "";
var prevSong = "";
var lyrics;
var words;
var startTimeMs;
var prevDur;

const getAccessToken = async () => {
  const basic = Buffer.from(`${client_id}:${client_secret}`).toString("base64");

  const response = await fetch(TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Basic ${basic}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: querystring.stringify({
      grant_type: "refresh_token",
      refresh_token,
    }),
  });

  return response.json();
};

function timer(m1, m2) {
  var minutes = Math.floor(m1 / 60000);
  var seconds = Math.ceil(m1 % 60000 / 1000);
  var totalMinutes = Math.floor(m2 / 60000);
  var totalSeconds = Math.ceil(m2 % 60000 / 1000);
  seconds = seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  totalSeconds = totalSeconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  return {minutes, seconds, totalMinutes, totalSeconds};
}

export const getNowPlaying = async (client_id, client_secret, refresh_token) => {
  const { access_token } = await getAccessToken(
    client_id,
    client_secret,
    refresh_token
  );

  return fetch(NOW_PLAYING_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const getLyrics = async (track) => {
    const response = await fetch("https://spotify-lyric-api-984e7b4face0.herokuapp.com/?trackid=" + track);
    return response
};

export default async function getNowPlayingItem(
  client_id,
  client_secret,
  refresh_token
) {
  const response = await getNowPlaying(client_id, client_secret, refresh_token).catch(e => console.log('Connection error', e));
  if (response.status === 204 || response.status > 400) {
    return false;
  }


  const song = await response.json();
  
  if (song.item == null) {
    return false;
  }

  const albumImageUrl = song.item.album.images[0].url;
  const artist = song.item.artists.map((_artist) => _artist.name).join(", ");
  const isPlaying = song.is_playing;
  const songUrl = song.item.external_urls.spotify;
  const title = song.item.name;
  const album = song.item.album.name;
  const progressms = song.progress_ms;
  const duration = song.item.duration_ms;
  const time = timer(progressms,duration)
  const percent = progressms / duration * 100;
  const currently_playing_type = song.currently_playing_type;
  const context = song.context;
  const progress = " " + time.minutes + ":" + time.seconds + " / " + time.totalMinutes + ":" + time.totalSeconds;
  const id = song.item.id
  
  runs++;
  
  if (prevSong != id) {
    errCount = 0;
    prevSong = id;
    lyrics = null;


  }
  if ((prevSong != id || lyrics == null || lyrics.status == '404') && lyrics == null) {
    lyrics = null;
    lyrics = await getLyrics(song.item.id).catch(err=>{})
    words = await lyrics.json(); 
    startTimeMs = 0
    if (lyrics != null || lyrics.status != '404') {
      errCount = 0
    }
  }
  
  if (prevDur > progressms) {
    startTimeMs = 0
    prevDur = progressms;

  } else {
    prevDur = progressms;
  }
  
  if (progressms >= startTimeMs && errCount <= 10) {
    console.log("0")
    var lyric;

    if (lyrics.status != "404") {

      var lines = words.lines;
      var timeDelay = 0
      var timeDelayReverse = 0
      if (words.lines.length != words.lines.filter(element => element.startTimeMs > progressms).length) {
        var timeDelay = 1
      }
      if (words.lines.filter(element => element.startTimeMs > progressms).length == 0) {
        var timeDelayReverse = -1
      }
      var line = lines[words.lines.length - words.lines.filter(element => (element.startTimeMs) > (progressms)).length - timeDelay];
      startTimeMs = lines[words.lines.length - words.lines.filter(element => (element.startTimeMs) > (progressms)).length - timeDelayReverse].startTimeMs
      lyric = line.words;
      prevLine = lyric;
    }
    else {
      
      lyric = prevLine;
      errCount++;
    }
    runs = 0;
  }
  

  lyric = prevLine;

  if (errCount > 10) {
    lyric = "";
  }

  return {
    albumImageUrl,
    artist,
    isPlaying,
    songUrl,
    title,
    album,
    percent,
    progress,
    currently_playing_type,
    context,
    lyric,
    id,
  };
}
