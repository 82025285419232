import React from 'react';
import ReactDOM from 'react-dom';
import SpotifyNowPlaying from './spotify/SpotifyNowPlaying';

import { inject } from '@vercel/analytics';

inject();

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
      <SpotifyNowPlaying />
  </React.StrictMode>,
  document.getElementById('root')
);
