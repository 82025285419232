import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Image,
  Text,
  Link,
  Spinner,
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react";
import { Global } from "@emotion/react"



import { StyleFunctionProps } from '@chakra-ui/theme-tools'

import styled from "styled-components";
import getNowPlayingItem from "./SpotifyAPI";
import SpotifyLogo from "./SpotifyLogo";
import PlayingAnimation from "./PlayingAnimation";

import '@fontsource/roboto';
import '@fontsource/cedarville-cursive';
import '@fontsource/leckerli-one';

const Fonts = () => (
  <Global
    styles={`
      /* Copied from https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Raleway&display=swap */
      /* latin-ext */
      @font-face {
        font-family: 'SF';
        src: url('./SFCompactDisplay-Bold.ttf');
    }
    @font-face {
        font-family: 'Natural';
        src: url('./natural.ttf');
    }
      
      `}
  />
)

const SpotifyNowPlaying = (props) => {
  const [loading, setLoading] = useState(true);
  const [track, setTrack] = useState(false);
  const [result, setResult] = useState({});
  const [lyric, setLyric] = useState({});
  const [background, setBackground] = useState("https://i.ibb.co/v4f3VM6/imageloading.png");
  useEffect(() => {

    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {

      var trackRelease;
      if (results[0].currently_playing_type == "track" && results[0].context.uri != null) {
        if (!results[0].context.uri.includes("3T1h6N9ySaSfe1uKc067Xp")) {
          trackRelease = true;
          setLyric(results[0].lyric)
        }
        else {
          trackRelease = false;
        results[0].albumImageUrl="https://mosaic.scdn.co/640/ab67616d0000b27321ebf49b3292c3f0f575f0f5ab67616d0000b27340c8a6ff21a1d52575185f38ab67616d0000b273a7c7f94cfccd5ab6de233916ab67616d0000b273d6627cd5a3fb6403c70471be"
        results[0].songUrl="https://open.spotify.com/playlist/4Cb45wrkcRDQkLvoatdt6f?si=4e30e91b5d054709"
        }
        

      }
      else {
        trackRelease = false;
        results[0].albumImageUrl="https://mosaic.scdn.co/640/ab67616d0000b27321ebf49b3292c3f0f575f0f5ab67616d0000b27340c8a6ff21a1d52575185f38ab67616d0000b273a7c7f94cfccd5ab6de233916ab67616d0000b273d6627cd5a3fb6403c70471be"
        results[0].songUrl="https://open.spotify.com/playlist/4Cb45wrkcRDQkLvoatdt6f?si=4e30e91b5d054709"
      } 
      setResult(results[0]);

      console.log(result[0])
      
      setLoading(false);
      setBackground(results[0].albumImageUrl)
      setTrack(trackRelease)
      

      

    });
  });

  return (
    <ChakraProvider theme={
      extendTheme({
      styles: {
        global: () => ({
          body: {
            backgroundImage: background,
            backgroundRepeat: "no-repeat", 
            backgroundSize: "cover",
            width: "calc(100vw)",
            height: "calc(100vh)",
            backdropFilter: "auto",
            backdropBlur: "10px",
            backdropBrightness: 0.4,
            transition: "background 1s",
            fontFamily: `'Roboto', sans-serif`,
          },
        }),
      },
    })}>
      <Fonts />
      <Stack align="center" position={'fixed'} top={5} alignItems={"center"} justifyContent={"center"} direction={"row"} color="white" fontSize={25} width={"100%"}>
        <Text fontFamily={`'Leckerli One', sans-serif`}>Harty </Text><Text fontWeight={700}>music</Text>
      </Stack>
    <Center>

  
      <Box >
        {loading ?
          <Stack align="center" mb={8}>
            <Stack height={10} borderRadius={10} alignItems={"center"} justifyContent={"center"} spacing={2} direction="row"  width={300}  backgroundColor={"#000000AA"} align="center">
              <Text fontWeight="semibold" color="white">Nick Is Idle On Spotify</Text>
              <Text fontWeight="semibold" color="white">{result.progress}</Text>
            </Stack>
            <Link href="https://open.spotify.com/playlist/4Cb45wrkcRDQkLvoatdt6f?si=4e30e91b5d054709" target="_blank">
            <Image src="https://i.ibb.co/v4f3VM6/imageloading.png" alt="My Image" width={300}></Image></Link>
            <Text color="#ffffff77" fontSize={12}>^ Reload To Check If Nick Is Musically Awake ^</Text>
            <Spinner size="md" speed="0.6s" thickness={3} color="gray.500" />
          </Stack>
          :
          (track ?
            <Stack width={300}  alignItems={"center"} justifyContent={"center"} mb={result.isPlaying ? 2 : 4} spacing={2}>
            <Stack height={10} borderRadius={10} alignItems={"center"} justifyContent={"center"} spacing={2} direction="row"  width={300}  backgroundColor={"#000000AA"} align="center">
              <Text fontWeight="semibold" color={result.isPlaying ? '#1DB954' : "#5da9f5"}>{(result.isPlaying ? 'Now playing' : "Paused") }</Text>
              {result.isPlaying && <PlayingAnimation />}
              <Text fontWeight="semibold" color="white">{result.progress}</Text>
            </Stack>
            <Box borderRadius={10} width={result.percent + "%"} height={2} transition="width 0.1s" backgroundColor={result.isPlaying ? '#1DB954' : "#5da9f5"}>
                </Box>
                <Link href={result.songUrl} target="_blank">
                  <Box
                          backgroundImage={result.albumImageUrl}
                          backgroundSize={"cover"}
                          width={300}
                          height={300}
                          border={0}
                          borderRadius={10}
                          _hover={{
                            transform: "scale(0.95)"
                          }}
                          transition={"transform 0.5s, background 1s"}
                        />
                </Link>
                
                <Text color={"#ffffff77"} fontSize={12}>^ Tap To Listen To This Song On Spotify ^</Text>
                <Box width={350} align="center">
                <Text
                      color={result.isPlaying ? '#1DB954' : "#5da9f5"}
                      fontSize={13}
                      fontWeight={500}
                      transition="content 1s"
                    >
                      {lyric}
                    </Text>
                </Box>
                
              <Box paddingTop={0} borderRadius="lg" width={300} align="center" textAlign={"center"}>
                <Stack direction="row" width={300} spacing={4} align="center">
                 
                  <Stack fontSize={20} spacing={0} overflow="hidden" width={300} >
                    <Link href={result.songUrl} target="_blank">
                      <Text
                        fontWeight="bold"
                        width="full"
                        isTruncated
                        color="white"
                      >
                        {result.title}
                      </Text>
                    </Link>
                    <Text
                      color="#ffffffAA"
                      isTruncated
                    >
                      {result.artist}
                    </Text>
                    <Text
                      color="#ffffffAA"
                      isTruncated
                    >
                      {result.album}
                    </Text>
                    <Text></Text>
                  </Stack>
                </Stack>
              </Box>
          </Stack>

          :

          <Stack width={300}  alignItems={"center"} justifyContent={"center"} mb={result.isPlaying ? 2 : 4} spacing={2}>
            <Stack height={10} borderRadius={10} alignItems={"center"} justifyContent={"center"} spacing={2} direction="row"  width={300}  backgroundColor={"#000000AA"} align="center">
              <Text fontWeight="semibold" color="white">{"Nick Is Idle On Spotify"}</Text>
            </Stack>
            <Link href={result.songUrl} target="_blank">
            <Image
                    alt={`${result.title} album art`}
                    src={result.albumImageUrl}
                    width={300}
                    height={300}
                    borderRadius={10}
                    _hover={{
                      transform: "scale(0.95)"
                    }}
                    transition={"transform 0.5s"}
                  />
                </Link>
                <Text color="#ffffff77" fontSize={12}>^ Tap To Listen To My Playlist In The Meantime^</Text>

              
          </Stack>
          )
          
        }
      </Box>
    </Center>
    </ChakraProvider>
  )
};

export default SpotifyNowPlaying;

const Center = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;